<template>
  <div id="app">
    <div class="bg_green" style="padding-bottom:3vw">
      <div class="white_box3" style="box-shadow:none">
        <div class="white_box3_in" style="padding-bottom:0;">
          <!-- 用户 -->
          <div class="user pd_3p6_0 clear" v-if="customerUser">
            <div class="user_avatar" :style="{backgroundImage:`url(${img})`}"></div>
            <div class="user_msg">
              <span v-if="customerUser.businessCustomerUser">{{ customerUser.businessCustomerUser.name }}</span>
              <label
                  v-if="customerUser.businessCustomerUser">{{
                  customerUser.businessCustomerUser.business.name
                }}，{{ customerUser.businessCustomerUser.structure.name }}</label>
            </div>
            <div class="user_r2">
              <button @click="unbind" class="button_dark">解除绑定</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="white_box4 mgt_3vw">
      <!--<router-link to="share">-->
      <!--<van-cell title="亲属共享" is-link/>-->
      <!--</router-link>-->
      <van-cell title="更换绑定银行卡"
                is-link to="bankChangeNo"
                v-if="paymentInfo && paymentInfo.needPassword === '1' && customerAccount && customerAccount.id"/>
      <van-cell title="修改密码"
                is-link @click="showPopup"
                v-if="paymentInfo && paymentInfo.needPassword === '1' &&customerAccount && customerAccount.id"/>
    </div>

    <div class="white_box4 mgt_3vw" v-if="posIdentity && posIdentity.posIdentityId">
      <van-cell title="一卡通">
        <template #right-icon>
          <div>{{ getPosIdentityState() }}</div>
          <van-switch v-model="posIdentityChecked" size="22px" active-color="#34AC40"
                      v-if="posIdentity.state === 'NORMAL' || posIdentity.state === 'REPORT_LOSS'"
                      style="margin-left: 4px;"
                      @change="onPosIdentityChange"/>
        </template>
      </van-cell>
    </div>

    <div class="white_box4 mgt_3vw">
      <van-cell title="关于我们"
                is-link
                @click="show_about=true"/>
    </div>

    <!-- 弹出修改密码 -->
    <van-popup v-model="show">
      <div class="white_box">
        <!-- 副标题 -->
        <div class="sub_title2">修改密码</div>
        <div class="txb">
          <van-field required
                     maxlength="6"
                     readonly
                     v-model="oldPass"
                     type="password"
                     label="旧密码"
                     placeholder="请点击输入旧密码"
                     @click="showKeyboard1=true"/>
        </div>
        <div class="txb">
          <van-field required
                     maxlength="6"
                     readonly
                     v-model="newPass"
                     type="password"
                     label="确认密码"
                     placeholder="请输入要修改的新密码"
                     @click="showKeyboard2=true"/>
        </div>
        <van-button class="button_green" :loading="setLoading" @click="setPass">
          确认
        </van-button>
      </div>
    </van-popup>

    <!-- 关于我们 -->
    <!-- <van-popup v-model="show2">
        <div class="white_box">
            <div class="sub_title2">关于我们</div>
            <div class="about">
                内容
            </div>
            <button class="button_green" @click="show2=false">确认</button>
        </div>
    </van-popup> -->
    <!-- 关于我们 -->
    <div class="about_all" v-show="show_about">
      <div class="about_all_in">
        <img src="../assets/images/about.jpg" alt="">
      </div>
    </div>

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
    </div>

    <!-- 数字键盘 -->
    <van-number-keyboard
        z-index="99999"
        v-model="oldPass"
        :show="showKeyboard1"
        @blur="showKeyboard1 = false"
    />

    <!-- 数字键盘 -->
    <van-number-keyboard
        z-index="99999"
        v-model="newPass"
        :show="showKeyboard2"
        @blur="showKeyboard2 = false"
    />
  </div>
</template>

<style scoped>
.white_box {
  width: 76vw;
  padding-bottom: 0;
  box-shadow: unset;
}

.button_green {
  margin: 16px auto;
  line-height: 13px;
  padding: 6px 26px;
}
</style>
<script>
import Vue from 'vue'
import {ACCESS_CustomerUser} from '@/store/mutation-types'
import {post} from '@/utils/http'
import {Dialog, Toast} from 'vant';
import Notify from "vant/es/notify";

export default {
  components: {},
  data() {
    return {
      showKeyboard1: false,
      showKeyboard2: false,

      loading: false,
      setLoading: false,
      customerUser: {},
      paymentInfo: {},

      img: require('../assets/images/avatar.png'),
      img2: require('../assets/images/avatar2.png'),
      show: false,
      show2: false,
      show_about: false,

      oldPass: '',
      newPass: '',
      customerAccount: {},

      posIdentity: null,
      posIdentityChecked: false,
    };
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }

    this.customerUser = Vue.ls.get(ACCESS_CustomerUser);
    this.loadBusinessPaymentInfo();

    post('api/customer/account/v2/find', {}, true).then(res => {
      this.customerAccount = res.data
    }).catch(() => {
    });

    // 查询一卡通
    post('api/business/v2/{businessId}/pos/identity/{businessCustomerUserId}/find'
        .replace('{businessId}', this.customerUser.businessId)
        .replace('{businessCustomerUserId}', this.customerUser.businessCustomerUserId), {}, true).then(res => {
      this.posIdentity = res.data;
      if (!this.posIdentity || !this.posIdentity.posIdentityId) {
        return;
      }

      if (this.posIdentity.state === 'NORMAL') {
        this.posIdentityChecked = true;
      } else if (this.posIdentity.state === 'REPORT_LOSS') {
        this.posIdentityChecked = false;
      }

    }).catch(() => {
    });
  },
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false);
  },
  methods: {
    goBack() {
      this.$router.push('/index');
      window.history.pushState('forward', null, '');
      window.history.forward(1);
    },
    setPass() {
      if (!this.oldPass) {
        Notify({type: 'warning', message: '旧密码不能为空'});
        return;
      }

      if (!this.newPass) {
        Notify({type: 'warning', message: '新密码不能为空'});
        return;
      }

      this.setLoading = true
      post('api/profile/v2/customer/password/update', {
        newPassword: this.newPass,
        oldPassword: this.oldPass
      }).then(res => {
        Dialog.alert({message: '密码修改成功'}).then(() => {
          this.show = false
        });
        this.setLoading = false
      }).catch(error => {
        Notify({type: 'warning', message: error.message});
        this.setLoading = false
        this.newPass = '';
        this.oldPass = '';
      });
    },
    showPopup() {
      this.show = true;
      this.newPass = '';
      this.oldPass = '';
    },
    showPopup2() {
      this.show2 = true;
    },
    showAbout() {
      this.show_about = true;
    },
    unbind() {
      Dialog.confirm({
        message: '您确定要执行此操作吗？'
      }).then(() => {
        this.loading = true
        post('api/customer/user/v2/customer/user/unbind', {
          businessCustomerUserId: this.customerUser.businessCustomerUserId
        }).then(res => {
          Vue.ls.set(ACCESS_CustomerUser, null)
          Dialog.alert({message: '解绑成功'}).then(() => {
            this.$router.push({path: '/index'})
          });
          this.loading = false
        }).catch(error => {
          Notify({type: 'warning', message: error.message});
          this.loading = false
        });
      });
    },
    onPosIdentityChange() {
      let title = '确认挂失一卡通？';
      let message = '挂失后，您将无法在校用一卡通消费就餐，请确认！如需办理新卡，请您联系学校工作人员。';
      if (this.posIdentity.businessCustomerUserType === 'STUDENT') {
        message = '挂失后，您的孩子将无法在校用一卡通消费就餐，请确认！如需办理新卡，请您联系学校工作人员。';
      }
      if (this.posIdentityChecked) {
        title = '确认启用一卡通？';
        message = '启用后一卡通将正常使用，请确认！';
      }

      Dialog.confirm({
        title: title,
        message: message
      }).then(() => {
        this.loading = true;
        post('api/business/v2/{businessId}/pos/identity/{posIdentityId}/update/state'
            .replace('{businessId}', this.customerUser.businessId)
            .replace('{posIdentityId}', this.posIdentity.posIdentityId), {
          state: this.posIdentityChecked ? "NORMAL" : "REPORT_LOSS"
        }).then(res => {
          Toast(this.posIdentityChecked ? '启用成功' : '挂失成功');
          if (this.posIdentityChecked) {
            this.posIdentity.state = 'NORMAL';
          } else {
            this.posIdentity.state = 'REPORT_LOSS';
          }
          this.loading = false
        }).catch(error => {
          this.posIdentityChecked = !this.posIdentityChecked;
          Notify({type: 'warning', message: error.message});
          this.loading = false
        });
      }).catch(() => {
        this.posIdentityChecked = !this.posIdentityChecked;
      });
    },

    getPosIdentityState() {
      if (this.posIdentity.state === 'NORMAL') {
        return '使用中';
      } else if (this.posIdentity.state === 'REPORT_LOSS') {
        return '已挂失';
      } else {
        return '已销卡';
      }
    },

    loadBusinessPaymentInfo() {
      post("api/business/v2/{businessId}/business/customer/user/paymentInfoQuery".replace('{businessId}', this.customerUser.businessId))
          .then(result => {
            this.paymentInfo = result.data;
          })
          .catch(error => {
            console.log(error.message);
          });
    }
  }
};
</script>
